import {
  UNAUTHORIZED,
  USER,
  USER_UPDATE,
  API_KEY,
  CREATE_API_KEY,
  DELETE_API_KEY
} from "constants/auth.constants";

import { getRole, getUserRoles } from "../../userRoles";

const initialState = {
  isAuthorized: null,
  isMfaRequired: null,
  loading: false,
  email: "",
  firstname: "",
  lastname: "",
  roles: [],
  avatar: null,
  moderated_companies: [],
  language: {},
  theme: "light",
  apiKey: null,
};

const mfaErrorCodes = [101, 102];

const getBrowserTheme = () => {
  return window.matchMedia("(prefers-color-scheme: dark)").matches
    ? "dark"
    : "light";
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER.REQUEST:
    case USER_UPDATE.REQUEST:
    case API_KEY.REQUEST:
    case CREATE_API_KEY.REQUEST:
    case DELETE_API_KEY.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER.SUCCESS:
    case USER_UPDATE.SUCCESS: {
      const { roles, dashboard_theme, ...data } = action.data;

      return {
        ...state,
        loading: false,
        isAuthorized: true,
        isMfaRequired: false,
        roles: getUserRoles({ roles }),
        role: getRole({ roles }),
        dashboard_theme: dashboard_theme || getBrowserTheme(),
        ...data,
      };
    }
    case USER_UPDATE.FAILURE:
    case API_KEY.FAILURE:
    case CREATE_API_KEY.FAILURE:
    case DELETE_API_KEY.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case USER.FAILURE:
      return {
        ...state,
        isAuthorized: false,
        isMfaRequired: false,
      };
    case UNAUTHORIZED: {
      const data = action.data;
      const code = data?.message?.message?.code;

      return {
        ...initialState,
        isAuthorized: false,
        isMfaRequired: mfaErrorCodes.includes(code),
      };
    }
    case API_KEY.SUCCESS:
    case CREATE_API_KEY.SUCCESS:
    case DELETE_API_KEY.SUCCESS: {
      const data = action.data;

      return {
        ...state,
        loading: false,
        apiKey: data
      }
    }
    default:
      return state;
  }
};
