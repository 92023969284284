import { combineReducers } from "redux";

import activityLog from "./activityLog";
import auth from "./auth";
import certification from "./certification";
import company from "./company";
import confirmation from "./confirmation";
import contentLibrary from "./contentLibrary";
import courses from "./courses";
import dashboard from "./dashboard";
import downloader from "./downloader";
import externalTraining from "./externalTraining";
import feedback from "./feedback";
import groups from "./groups";
import info from "./info";
import introduction from "./introduction";
import invitation from "./invitation";
import modal from "./modal";
import overview from "./overview";
import questionAnalytics from "./questionAnalytics";
import questions from "./questions";
import shares from "./shares";
import signup from "./signup";
import signupURLs from "./signupURLs";
import topics from "./topics";
import users from "./users";
import reporting from "./reporting";
import marketplace from "./marketplace";
import messages from "./messages";
import webhooks from "./webhooks";

export default combineReducers({
  auth,
  dashboard,
  overview,
  company,
  users,
  modal,
  groups,
  topics,
  questions,
  questionAnalytics,
  signupURLs,
  shares,
  contentLibrary,
  feedback,
  introduction,
  activityLog,
  signup,
  confirmation,
  downloader,
  info,
  certification,
  externalTraining,
  courses,
  invitation,
  reporting,
  marketplace,
  messages,
  webhooks
});
