import NotFound from "pages/notFound";
import React from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ROLES } from "userRoles";

import Loading from "./loading";

const PrivateRoute = ({
  isAuthorized,
  isMfaRequired,
  children,
  userRoles,
  fallbackRoute,
  features,
  requiredFeature,
  requiredRole = [],
}) => {
  if (requiredRole && !Array.isArray(requiredRole)) requiredRole = [requiredRole];

  const location = useLocation();
  const isAdmin = userRoles.includes(ROLES.admin);
  const hasRole = userRoles.some((role) => requiredRole.includes(role));
  const hasFeature = features[requiredFeature];
  const navigate = useNavigate();

  if (isAuthorized === null) {
    return <Loading active={true} />;
  }

  if (isAuthorized === false) {
    navigate("/login", {state: { from: location }});
  }

  if (isMfaRequired) {
    navigate("/login/mfa", {state: { from: location }});
  }

  if (
    !isAdmin &&
    ((requiredRole.length && !hasRole) || (requiredFeature && !hasFeature))
  ) {
    if (fallbackRoute) {
      navigate(fallbackRoute);
    }
    return <NotFound />;
  }

  return <>{children}</>;
};

const mapStateToProps = (state) => {
  const {
    auth: {
      user: { isAuthorized, isMfaRequired, roles: userRoles },
    },
    company: { features },
  } = state;

  return {
    isAuthorized,
    isMfaRequired,
    userRoles,
    features,
  };
};

export default connect(mapStateToProps)(PrivateRoute);
