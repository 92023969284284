import loadable from "@loadable/component";
import { PrivateRoute } from "components";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { toast } from "react-toastify";

import { getCurrentUser } from "../actions";
import { getInfo } from "../actions/info.actions";
import ErrorBoundary from "../errorBoundary";
import strings, { setLanguage } from "../l10n";
import { SentryRoutes } from "routes";
import { ROLES } from "../userRoles";

const Login = loadable(() => import("./auth"));
const Logout = loadable(() => import("./logout"));
const SignupRoutes = loadable(() => import("./signup"));
const TrainingConfirmationRoutes = loadable(() =>
  import("./trainingConfirmation"),
);
const DashboardPage = loadable(() => import("./dashboard"));
const ConfirmationRoutes = loadable(() => import("./confirmation"));
const Marketplace = loadable(() => import("./marketplace"));

const BaseRouter = ({ getCurrentUser, getInfo, user, company }) => {
  useEffect(() => {
    getCurrentUser();
    getInfo();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const errorMessage = urlParams.get("error_message");

    if (errorMessage) {
      toast.error(errorMessage, {
        autoClose: false,
      });
    }
  }, []);

  const {
    language: { dashboard: userLanguage },
  } = user;
  const {
    language: { dashboard: companyLanguage },
  } = company;

  const language =
    userLanguage || companyLanguage || strings.getInterfaceLanguage();

  setLanguage(language);

  return (
    <SentryRoutes key={language}>
      <Route exect path="/login/*" element={<Login />} />
      <Route exect path="/logout" element={<Logout />} />
      <Route path="/signup/*" element={<SignupRoutes />} />
      <Route path="/confirm/*" element={<ConfirmationRoutes />} />
      <Route
        path="/confirm-training/*"
        element={<TrainingConfirmationRoutes />}
      />

      <Route
        path="/marketplace/*"
        ErrorBoundary={ErrorBoundary}
        element={
          <PrivateRoute requiredRole={[ROLES.content_creator]}>
            <Marketplace />
          </PrivateRoute>
        }
      />

      <Route
        path="/*"
        ErrorBoundary={ErrorBoundary}
        element={
          <PrivateRoute>
            <DashboardPage />
          </PrivateRoute>
        }
      />
    </SentryRoutes>
  );
};

export default connect(
  (state) => {
    const {
      auth: { user },
      company,
    } = state;

    return {
      user,
      company,
    };
  },
  (dispatch) => ({
    getCurrentUser: () => dispatch(getCurrentUser()),
    getInfo: () => dispatch(getInfo()),
  }),
)(BaseRouter);
