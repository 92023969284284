import { paginationActions, requestActions } from "../utils";

export const PARENT_GROUP = requestActions("ParentGroup/Get");
export const DEFAULT_GROUP = requestActions("DefaultGroup/Get");
export const GROUPS = requestActions("Groups/List");
export const GROUP = requestActions("Group/Get");
export const GROUP_UPDATE = requestActions("Group/Update");
export const GROUP_CREATE = requestActions("Group/Create");
export const GROUP_SUBMIT = requestActions("Group/Submit");
export const GROUP_DELETE = requestActions("Group/Delete");
export const GROUP_RESET = "Group/Reset";
export const PAGINATION = {
  ...paginationActions("Groups/Paginate"),
  SET_MAX_LEVEL: "Groups/Paginate/SetMaxLevel",
};
export const GROUPS_DELETE = requestActions("Groups/Delete");
export const GROUPS_MOVE = requestActions("Groups/Move");
export const GROUP_TREE_UPDATE = requestActions("Group/Tree/List");
export const COLLAPSE_GROUP_TREE = "Group/Tree/Collapse";
export const SELECT = "Groups/Select";
export const SELECT_ALL = "Groups/Select/All";
export const SHOW_COLUMNS = "Groups/ShowColumns";
export const GROUPS_TOGGLE_MOVE_MODAL = "Groups/Modals/Move/Toggle";
