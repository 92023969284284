import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { Multiselect as MultiselectBase } from "react-widgets";

import styles from "./multiSelect.module.scss";

const Multiselect = (props) => {
  //  Original Multiselect component open/close actions are a bit wonky and caret icon is not visible.
  //  We override default icon and handle open/hide ourselves

  const [open, setOpen] = useState(false);
  const elRef = useRef();
  const { label } = props;

  const [disabledInput, setDisabledInput] = useState(false);
  const [disabledValues, setDisabledValues] = useState(undefined);

  useEffect(() => {
    const { disabled } = props;
    setDisabledInput(!Array.isArray(disabled) && disabled);
    setDisabledValues(Array.isArray(disabled) && disabled);
  }, [props]);

  useEffect(() => {
    //  Detect whether user clicked outside of the dropdown component
    const _handleClickOutside = ({ target }) => {
      if (!open || !elRef.current) {
        return;
      }
      let clickedInside = false;
      let toIterate = [...elRef.current.children];

      while (toIterate.length) {
        const el = toIterate.shift();
        if (el.children.length) {
          toIterate = [...toIterate, ...el.children];
        }
        if (el === target) {
          clickedInside = true;
          break;
        }
      }

      if (!clickedInside) {
        setOpen(false);
      }
    };

    document.body.addEventListener("click", _handleClickOutside);
    return () =>
      document.body.removeEventListener("click", _handleClickOutside);
  });

  return (
    <div className={"d-flex flex-column w-100"}>
      {label && <label>{label}</label>}

      <div
        className={styles.multiselectWrapper}
        ref={elRef}
        onClick={() => (open || disabledInput ? null : setOpen(true))}
      >
        <MultiselectBase
          {...props}
          selectIcon={<></>}
          open={open}
          disabled={disabledValues || disabledInput}
        />

        <div
          className={styles.caretIconWrapper}
          onClick={() => (disabledInput ? null : setOpen(!open))}
        >
          <i className={classNames("fas fa-caret-down")} />
        </div>
      </div>
    </div>
  );
};
export default Multiselect;
