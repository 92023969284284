const isString = (value) =>
  typeof value === "string" || value instanceof String;

export const sortAscending = (a, b, caseSensitive = true) => {
  if (!caseSensitive) {
    if (isString(a)) a = a.toLowerCase();
    if (isString(b)) b = b.toLowerCase();
  }
  return a > b ? 1 : -1;
};

export const makeArray = (val) => {
  if (!Array.isArray(val)) {
    return [val];
  }
  return val;
};

export const addToArray = (array, val) => {
  if (array.includes(val)) return;
  return [...array, val];
}

export const removeFromArray = (array, val) => {
  if (!array.includes(val)) return;
  return array.filter(it => it !== val);
}

export const findDuplicates = (array) => array.filter((item, index) => array.indexOf(item) !== index);

export const isEqual = (arr1, arr2) => arr1.length === arr2.length && arr1.every((val, idx) => val === arr2[idx]);